import React from "react"
import { Link } from "gatsby"

// Utilities
import kebabCase from "lodash/kebabCase"

import styles from "./tags-list.module.css"

const TagsList = ({ tags }) => {
  console.log(tags, styles);

  if(tags) {
    return <ul className={styles.tags}>
        <li className={styles.tags__label}>This post is about</li>
        {tags.map(tag => (
            <li className={styles.tags__tag} key={tag}>
            <Link to={`/tags/${kebabCase(tag)}/`} className={styles.tags__link}>
                {tag}
            </Link>
            </li>
        ))}
    </ul>
    }
    else {
        return null;
    }
}

export default TagsList